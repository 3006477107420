import React from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTamanhoTela } from "../hooks/useTamanhoTela";

import videoBgDesktop from "../assets/video/video-navalha-desktop.mp4";
import videoBgMobile from "../assets/video/video-navalha-mobile.mp4";


const Background = styled.div`
  position: absolute;
  z-index: -1;
  max-height: 80vh;
  video {
    max-width: 100%;
    max-height: 100%;
  }
  @media only screen and (min-width: 1600px) {
    video {
      width: 100vw;
    }
  }

  @media only screen and (max-width: 700px) {
    max-height: 100vh;
    video {
      height: 100%;
    }
  }
`;
const Container = styled.div`
  display: flex;
  height: 80vh;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media only screen and (max-width: 600px) {
    padding: 22px 16px;
    /* padding-bottom: 0px; */
    height: 75vh;
    flex-direction: column;
    background-size: cover;
  }
  @media only screen and (min-width: 1600px) {
    padding: 300px 80px;
    background-size: cover;
    height: 100%;
  }
`;

const TitleAboutUs = styled.p`
  font-size: ${props => props.isMobile ? '28px' : '32px'};
  margin: 0px;
  text-align: center;
  color: white;
  font-family: Montserrat-ExtraBold;
  z-index: 1;
  font-weight: 700;
`;
const TitleAboutUs2 = styled.h3`
  font-size: ${props => props.isMobile ? '68px' : '88px'};
  margin: 0px;
  text-align: center;
  color: #fa951a;
  font-weight: 700;
  z-index: 1;
  font-family: Montserrat-ExtraBold;
`;

const Paragraf = styled.p`
  font-size: ${props => props.isMobile ? '18px' : '22px'} ;
  width: 80%;
  margin-bottom: 0px;
  line-height: 24px;
  color: white;
  font-weight: 700;
  z-index: 1;
  font-family: Montserrat;
  text-align: justify;
  @media only screen and (min-width: 1600px) {
    margin-bottom: 42px;
    line-height: 26px;
  }
`;


function AboutUs2() {
  const { isMobile, isDesktop, isDesktopLarge } = useTamanhoTela();
  AOS.init();

  return (
    <>
      <Background>
        <video muted autoplay="true" loop src={isMobile ? videoBgMobile : videoBgDesktop}></video>
      </Background>
        <Container id="sobre">
          <TitleAboutUs isMobile={isMobile}>Conheça-nos</TitleAboutUs>
          <TitleAboutUs2 isMobile={isMobile}>Nossa História</TitleAboutUs2>
          <Paragraf isMobile={isMobile}>
            Guto, um jovem soldado brasileiro enviado em missão de paz ao Haiti em
            2014, descobriu seu talento para cortar cabelos durante seu serviço.
            Após retornar ao Brasil em 2016, ele e sua esposa Rose fundaram a
            Navalha de Ouro em Natal, no bairro de Nova Parnamirim. O sucesso do
            negócio levou a uma expansão, agora oferecidos no renovado estabelecimento chamado
            Gutto Queiroz Barbearia.
          </Paragraf>
        </Container>
    </>
  );
}

export { AboutUs2 };
export default AboutUs2;
