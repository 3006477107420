import React from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 82px 280px;
  background-color: #080808;
  color: #fefefe;
  border-top: 1px solid #fa951a;

  @media only screen and (max-width: 600px) {
    padding: 82px 16px;
    flex-direction: column;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 33%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const TitleServices = styled.h3`
  font-size: 64px;
  margin: 0px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-family: Montserrat-extrabold;
`;

const UlTitle = styled.h4`
  font-size: 26px;
  margin: 0px;
  text-transform: uppercase;
  text-align: left;
  font-family: Montserrat-extrabold;
`;

const Ul = styled.ul`
  width: 100%;
`;

const Li = styled.li`
  width: 100%;
  font-size: 20px;
`;

const StrapContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 0px;
  background-color: #fa951a;
  align-items: center;
  justify-content: center;
`;

const CallToAction = styled.span`
  all: unset;
  color: #fefefe;
  font-size: 18px;
  margin-top: ${(props) => props.marginTop || 0};

  @media only screen and (max-width: 600px) {
    margin: 0 16px;
  }
`;

const OrcamentoBtn = styled.a`
  all: unset;
  border: 3px solid #fefefe;
  padding: 4px 8px;
  color: #fefefe;
  font-size: 14px;
  cursor: pointer;
  transition: 200ms;
  margin-left: 16px;

  /* text-transform: uppercase; */

  :hover {
    /* padding: 10px 18px;
    font-size: 22px; */
    background-color: #fefefe;
    color: #fa951a;
    border-color: #fefefe;
  }

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 1600px) {
    font-size: 18px;
  }
`;

const SeeMore = styled.a`
  all: unset;
  color: #fefefe;
  font-size: 18px;
  margin-top: 42px;
  cursor: pointer;
  text-align: center;
  transition: 200ms;

  :hover {
    color: #fa951a;
  }
`;

function Services() {
  AOS.init();

  return (
    <>
      <Container id="servicos">
        <TitleServices data-aos="fade-left">Nossos Serviços</TitleServices>

        <SubContainer data-aos="fade-left">
          <Ul>
            <Li>Cabelo Masculino</Li>
            <Li>Barba</Li>
            <Li>Penteados</Li>
            <Li>Desenhos Freestyle</Li>
            <Li>Combos de Cabelo e Barba</Li>
            <Li>Manicure Masculino</Li>
            <Li>Retirada de calosidades</Li>
            <br />
            <SeeMore href="/servicos" data-aos="fade-left">
              Ver mais
            </SeeMore>
          </Ul>
        </SubContainer>

      
      </Container>

      <StrapContainer>
        <CallToAction>
          Desfrute do nosso ambiente aproveitando nossos serviços
          <OrcamentoBtn
            href="https://api.whatsapp.com/send?phone=+5584999886431&text=Olá,%20vim%20através%20do%20site%20e%20quero%20saber%20a%20disponibilidade..."
            target="_blank"
          >
            Conferir disponibilidade
          </OrcamentoBtn>
        </CallToAction>
      </StrapContainer>
    </>
  );
}

export { Services };
export default Services;
